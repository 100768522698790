<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button variant="success" v-b-modal.new-modal>ثبت کوپن</b-button>
    <b-card title="کوپن‌ها" class="mt-3">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="title">عنوان</label>
                  <b-form-input id="title" v-model="searchParams.title"></b-form-input>
                </div>
              </div>
              <div class="col-md-3">
                <b-form-group label="کد" label-for="code">
                  <b-form-input id="code" v-model="searchParams.code"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label="تاریخ شروع" label-for="start_date">
                  <date-picker type="datetime" v-model="searchParams.start_date" format="jYYYY-jMM-jDD HH:mm" />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label="تاریخ پایان" label-for="end_date">
                  <date-picker type="datetime" v-model="searchParams.end_date" format="jYYYY-jMM-jDD HH:mm" />
                </b-form-group>
              </div>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">

        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | persianDate }}
        </template>

        <template v-slot:cell(start_date)="data">
          <span v-if="data.item.start_date"> {{data.item.start_date | persianDate}}</span>
        </template>

        <template v-slot:cell(end_date)="data">
          <span v-if="data.item.end_date"> {{ data.item.end_date | persianDate}}</span>
        </template>

        <template v-slot:cell(status)="data">
          <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
          <span class="badge badge-light" v-else>غیرفعال</span>
        </template>

        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
    </pagination>
    <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
    </pagination>

    <b-modal id="new-modal" title="ثبت کوپن" size="lg" hide-footer>
      <div>
        <form @submit.prevent="newItem" id="new-item">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-input id="title" name="title" data-required :disabled="disabled"></b-form-input>
              </b-form-group>

              <b-form-group>
                <label for="code"> کد <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-input id="code" name="code" data-required :disabled="disabled"></b-form-input>
              </b-form-group>

              <b-form-group label="سقف استفاده" label-for="usage_limit">
                <b-form-input id="usage_limit" name="usage_limit" data-required :disabled="disabled"></b-form-input>
              </b-form-group>

              <b-form-group>
                <label for="discount"> درصد تخفیف <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input type="number" id="discount" name="discount" data-required :disabled="disabled">
                </b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="تاریخ شروع" label-for="start_date">
                <date-picker type="datetime" name="start_date" format="jYYYY-jMM-jDD HH:mm" />
              </b-form-group>
              <b-form-group label="تاریخ پایان" label-for="end_date">
                <date-picker type="datetime" name="end_date" format="jYYYY-jMM-jDD HH:mm" />
              </b-form-group>
            </div>
          </div>

          <div class="clearfix">
            <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="edit-modal" title="ویرایش کوپن" size="lg" hide-footer>
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-input id="title" name="title" v-model="edit.title" data-required :disabled="disabled">
                </b-form-input>
              </b-form-group>

              <b-form-group>
                <label for="code"> کد <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-input id="code" name="code" v-model="edit.code" data-required :disabled="disabled">
                </b-form-input>
              </b-form-group>

              <b-form-group label="سقف استفاده" label-for="usage_limit">
                <b-form-input id="usage_limit" name="usage_limit" v-model="edit.usage_limit" data-required
                  :disabled="disabled"></b-form-input>
              </b-form-group>

              <b-form-group>
                <label for="discount"> درصد تخفیف <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input type="number" id="discount" name="discount" v-model="edit.discount" data-required
                  :disabled="disabled"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="تاریخ شروع" label-for="start_date">
                <date-picker  :value="edit.start_date"  input-format="YYYY-MM-DD HH:mm" type="datetime" format="jYYYY-jMM-jDD HH:mm" name="start_date" />
              </b-form-group>
              <b-form-group label="تاریخ پایان" label-for="end_date">
                <date-picker  :value="edit.end_date"  input-format="YYYY-MM-DD HH:mm" type="datetime" format="jYYYY-jMM-jDD HH:mm" name="end_date" />
              </b-form-group>
            </div>
          </div>
          <div class="clearfix">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="put" name="_method">
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import mixins from './mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/coupon',
        title: 'کوپن ها',
        table_fields: [{
            key: 'id',
            label: 'شناسه '
          },
          {
            key: 'title',
            label: 'عنوان'
          },
          {
            key: 'code',
            label: 'کد'
          },
          {
            key: 'start_date',
            label: 'تاریخ شروع'
          },
          {
            key: 'end_date',
            label: 'تاریخ پایان'
          },
          {
            key: 'usage_limit',
            label: 'سقف استفاده'
          },
          {
            key: 'discount',
            label: 'درصد تخفیف'
          },
          {
            key: 'usage_count',
            label: 'کاربران'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          {
            key: 'edit',
            label: 'ویرایش '
          },
          {
            key: 'delete',
            label: 'حذف'
          }
        ]
      }
    },
    computed: {
      editStartDate() {
        return window.moment(this.edit.start_date).format('jYYYY-jMM-jDD HH:MM')
      },
      editEndDate() {
        return window.moment(this.edit.end_date).format('jYYYY-jMM-jDD HH:MM')
      }
    },
    methods: {

    },
    created() {
      this.loadItems()
      this.searchParams = {
        title: '',
        code: '',
        start_date: '',
        end_date: '',
      }
    }
  }
</script>